import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const GalleryItem = ({ image, caption, id }) => {
  let storyLink = ''
  let StringCaption = new String(caption)
  let title = StringCaption

  if (StringCaption.includes('#wild')) {
    storyLink = '/contact'
    title = StringCaption.replace('#wild', '')
  }

  if (storyLink) {
    return (
      <Link to={storyLink}>
        <div className="gallery-item-link">
          <h2>{title}</h2>
          <GatsbyImage image={image} key={id} alt={title || 'Instagram Post'} />
        </div>
      </Link>
    )
  } else {
    return (
      <div className="gallery-item">
        <h2>{title}</h2>
        <GatsbyImage image={image} key={id} alt={title || 'Instagram Post'} />
      </div>
    )
  }
}

export default GalleryItem
